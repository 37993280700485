<!-- header.component.html -->
<div class="header">
    <div class="logo">
      <a routerLink="/">What India Needs</a>
    </div>
    <div class="menu">
      <a routerLink="/" routerLinkActive="active">Home</a>
      <a routerLink="/about" routerLinkActive="active">About</a>
      <a routerLink="/contact" routerLinkActive="active">Contact</a>
      <!-- Add more menu items as needed -->
    </div>
  </div>
  